@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.preloader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: rgb(49, 49, 56);
    background-position: center center;
    background-repeat: no-repeat;
    opacity: 1;
    transition: 1s opacity;
  }
  .preloader.loaded {
    opacity: 0;
    visibility: hidden;
  }
  .preloader .spinner_wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 33333;
  }
  .preloader .spinner {
    width: 50px;
    height: 50px;
    background-color: rgba(255, 255, 240, 0.8);
    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
  }
  
  @-webkit-keyframes sk-rotateplane {
    0% {
      -webkit-transform: perspective(120px);
    }
    50% {
      -webkit-transform: perspective(120px) rotateY(180deg);
    }
    100% {
      -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
    }
  }
  @keyframes sk-rotateplane {
    0% {
      transform: perspective(120px) rotateX(0deg) rotateY(0deg);
      -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
      transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
      -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
      transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
      -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
  }
  
.logo {
    width: 100px;
}
body {
  color: #fff;
  font-family: "Roboto Condensed", sans-serif;
  background-image: url(https://lh3.googleusercontent.com/MwjzOaQCTUjhBA6vFnDNJ_SQqnld2TVzGzj0uxR4t916Mt7h8gpEM0tU4yNMABQdDfyw07QlqMIm180i95J3mTvjAQDWVPnYi9nI2SGZ2B5-6nlVYKttXagH2SCECiPhkdY8L8JkMnsLt9sJ9mJEBAMDLYFZRp5QTITs7xrH3Qi70JQpUY1JnF8h8zv46Ae8K6Akgk5V-NM3PuB5BCC15MCNLN8h1FarM4jN0cRNj8MzBUZbOYx6fh9CZ-I-D6sRU5ixF11JBbWYq_5V3IP00BiyD0qwHIAgvjHSur4WP7TEncCADoqnd87zqRGNPI9yj5lw_RBSzHO_uEfdC2YA9qeRfHIv-fvt98PVKMEYyPXZxx9B3NtSFjoPFN38dYtnJlI8OCOtJ5lRtqyFELKOpBj2ZrfCT8ZiGC1q2DpwUwU_Diu-s7K1_knbekRvSmGTK0k0xLmIVC8I2xW691WjqdOu69Wvg9cqWMfKBQVhfs2P427yr2KuH5VK-cglohn88d9Vm95pLZOXx5WkSZm2scTLuky6karCmS5t6RQCnuwxbb7MDe-57hZOUhO7SdJC0MCeOHOclTu3JKqCQu8QsDtDyX1iuVGEwvZvBKZd-j2m2-hQgMQ_exS5llUjiPgMv0NxSDPeqzIK17QALCXaJz3GEd1-IVsyVQtJkgFPCDtzmTEA8zzzuWhie7i9rRSu36y1OgoNqDtpm4O_ACXyGXgR93syow9N9QggPfWP67JC_nyf4LGYBP4=w1718-h1147-no);
  background-position: top top;
  background-size: cover;
  margin: 0;
}

.App {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: rgb(49, 49, 49);
  background: linear-gradient(
    0deg,
    rgba(49, 49, 49, 1) 0%,
    rgba(49, 49, 49, 1) 60%,
    rgba(49, 49, 49, 0.9) 100%
  );
}

.container {
  width: 100%;
  margin: 15% auto;
}
.col-4 {
  width: 25%;
  float: left;
}

h1 {
  font-size: 38px;
  text-transform: uppercase;
  line-height: 1;
}

@media (min-width: 768px) {
  .container {
    width: 1100px;
  }
  h1 {
    font-size: 58px;
  }
}

